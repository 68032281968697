import styles from "./ChatPopup.module.scss"

export default function ChatPopup({title, subtitle, isInForm = false}) {
	return (
		<div className={styles.finishText}>
			<div className={styles.image}></div>
			<div className={styles.finishTitle} dangerouslySetInnerHTML={{__html: title}}></div>
			<div className={styles.subTitle} style={isInForm ? {padding: "0 40px"} : {}} dangerouslySetInnerHTML={{__html: subtitle}}></div>
		</div>
	)
}
