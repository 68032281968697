export const config = {
	models: [
		{
			id: "s5",
			folder: "s5",
			name: "S5",
			options: [
				"’Линзованные’ светодиодные фары",
				"Двигатель 1.5 113 л.с. CVT",
				"Двигатель 1.5 Turbo 147 л.с. CVT",
				"Панорамный люк",
				"Боковые передние подушки безопасности",
				"Система кругового обзора",
				"Шторки безопасности",
				"Обогрев сидений 2-го ряда",
				"Обогрев рулевого колеса",
				"Обогрев лобового стекла",
				"Обогрев форсунок стеклоомывателя",
				"Климат-контроль, 1 зона",
				"Беспроводная зарядка для телефона",
			],
			colors: [
				{
					hex: "#101010",
					text: "Черный",
				},
				{
					hex: "#8A919B",
					text: "Серый",
				},

				{
					"hex": "#455A85",
					text: "Синий",
				},
				{
					hex: "#D7241F",
					text: "Красный",
				},
				{
					"hex": "#616872",
					text: "Стальной",
				},
				{
					"hex": "#F8F8F7",
					text: "Белый",
				},
				{
					hex: "",
					text: "Другой",
				},
			]
		},
		{
			id: "c5",
			folder: "c5",
			name: "C5",
			options: [
				"Полностью светодиодная оптика",
				"Двигатель 1.5 Turbo 147 л.с. CVT (FWD)",
				"Двухцветная окраска кузова (контрастная крыша",
				"Панорамный люк",
				"Система кругового обзора и передние датчики парковки",
				"Адаптивный круиз-контроль",
				"Автоматическое переключение ближнего/дальнего света",
				"Вентиляция передних сидений",
				"Обогрев сидений 2-го ряда",
				"Голосовое управление",
				"Спортивные сиденья",
				"Беспроводная зарядка для телефона",
			],
			colors: [
				{
					hex: '#6D767B',
					text: 'Silver Premium Double Color',
				},
				{
					hex: '#8BAA95',
					text: 'Green Premium Double Color',
				},

				{
					hex: '#000000',
					text: 'Black',
				},
				{
					hex: '#1C2C3B',
					text: 'Dark Blue',
				},
				{
					hex: '#404853',
					text: 'Grey Premium',
				},
				{
					hex: '#929FB5',
					text: 'Vanilla Blue',
				},
				{
					hex: '#F8F8F7',
					text: 'White Premium Double Color',
				},
				{
					text: 'Другой',
				},
			]
		},
	],
}

export const colors = [
	{
		hex: "#101010",
		text: "Черный",
	},
	{
		hex: "#8A919B",
		text: "Серый",
	},

	{
		"hex": "#455A85",
		text: "Синий",
	},
	{
		hex: "#D7241F",
		text: "Красный",
	},
	{
		"hex": "#616872",
		text: "Стальной",
	},
	{
		"hex": "#F8F8F7",
		text: "Белый",
	},
	{
		hex: "",
		text: "Другой",
	},
]

export const options = [
	{
		name: "Кредит",
	},
	{
		name: "Наличные",
	},
	{
		name: "Трейд-Ин",
	},
	{
		name: "Рассрочка",
	}
]
