import styles from "./ChatFooter.module.scss"

export default function ChatFooter() {
	return (
		<footer className={styles.root}>
			<div className={styles.footerWrap}>
				ООО "МАКСИМУМ КРЕДИТ"<br/>
				ИНН 7804540104<br/>
				ОГРН 1147847302954<br/>
				195299, город Санкт-Петербург,<br/>
				ул. Руставели, д. 53 литер а, помещ. 2.8
			</div>
		</footer>
	)
}
