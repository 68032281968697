import "./assets/styles/normalize.css"
import "./assets/styles/globals.css"
import "keen-slider/keen-slider.min.css"

import {lazy, Suspense} from "react"

import {Route, Routes} from "react-router-dom"
import NotificationPermission from "./NotificationPermission"
import {ModalProvider} from "./contexts/ModalContext"
import MainPage from "./components/MainPage/MainPage"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import ChatPage from "./test-pages/ChatPage/ChatPage";
import {config as chatConfig} from "./test-pages/ChatPage/configChat.json"
import ArticleHeader from "./components/Articles/ArticleHeader/ArticleHeader";

const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"))
const Advantage = lazy(() => import("./test-pages/Articles/Advantage/Advantage"))
const ClientsReviews = lazy(() => import("./test-pages/Articles/ClientsReviews/ClientsReviews"))
const Info = lazy(() => import("./test-pages/Articles/Info/Info"))

function App() {
	const subdomain = window?.location?.pathname?.split("/")[1]
	return (
		<div className='App'>
			<NotificationPermission />
			{subdomain === "chat" ? <></> : ["chat", "advantage", "clients-reviews", "info"].includes(subdomain) ? <ArticleHeader /> :<Header />}
			<ModalProvider>
				<Routes>
					<Route path='/' element={<MainPage />} />
					<Route path={"/chat"} element={<ChatPage models={chatConfig.models} />} />
					<Route
						path='advantage'
						element={
							<Suspense fallback={<Fallback />}>
								<Advantage />
							</Suspense>
						}
					/>
					<Route
						path='clients-reviews'
						element={
							<Suspense fallback={<Fallback />}>
								<ClientsReviews />
							</Suspense>
						}
					/>
					<Route
						path='info'
						element={
							<Suspense fallback={<Fallback />}>
								<Info />
							</Suspense>
						}
					/>
					<Route
						path='privacy-policy'
						element={
							<Suspense fallback={<Fallback />}>
								<PrivacyPolicy />
							</Suspense>
						}
					/>
				</Routes>
			</ModalProvider>
			{subdomain === "chat" ? <></> : <Footer />}
		</div>
	)
}

function Fallback () {
	return <div style={{paddingTop: "calc(50vh - 40px)", paddingBottom: "calc(50vh - 40px)"}} className={"container"}>
		Loading...
	</div>
}

export default App
