import styles from "./ChatLogo.module.scss"

export default function ChatLogo() {
	return (
		<div className={styles.root}>
			<img alt='logo' className={styles.image} src='/images/logo.svg' width={245} height={48} />
			<div className={styles.divider} />
			<p className={styles.text}>
				Официальный дилер OMODA<br/>
				МАКСИМУМ Север
			</p>
		</div>
	)
}
