import {decimalFormatter} from "./formatters";

export const getMaxBenefit = (benefits) => {
	if (!benefits) {
		return null
	}

	if (typeof benefits[0] === "string") {
		benefits = benefits.map(benefit => +benefit.replace(/\s/g, ''))
	}

	return decimalFormatter(benefits.sort((a, b) => b - a)[0] ?? "")
}
