import styles from "./ChatBanner.module.scss"
import {animated, easings, useSpring} from "@react-spring/web"

export default function ChatBanner({maxBenefit}) {
	const springs = useSpring({
		from: {y: 100},
		to: {y: 0},
		delay: 500,
		config: {
			duration: 500,
			easing: easings.easeOutCubic,
		},
	})

	return (
		<animated.div style={{...springs}} className={styles.root}>
			<div className={styles.info}>
				<h1 className={styles.title}>Грандиозные выгоды до {maxBenefit}&nbsp;₽ на покупку автомобилей ОМОDА</h1>
				<p className={styles.subtitle}>
					Забронируй свою максимальную выгоду
				</p>
			</div>
		</animated.div>
	)
}
