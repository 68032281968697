import {useEffect, useState} from "react"

import Chat from "./components/Chat/Chat"
import ChatBanner from "./components/ChatBanner/ChatBanner"
import ChatHeader from "./components/ChatHeader/ChatHeader"
import styles from "./ChatPage.module.scss"
import ChatFooter from "./components/ChatFooter/ChatFooter"
import {getDataFromApi} from "../../api/getDataFromApi"
import {getModelsWithBenefit} from "../../utils/getModelsWithBenefit"
import ChatPopup from "./components/ChatPopup/ChatPopup"

export default function ChatPage({models}) {
	const [data, setData] = useState(null)
	const [send, setSend] = useState(false)
	useEffect(() => {
		getDataFromApi("omoda", "sales.maximum-omoda.ru/chat").then((r) => setData(r))
	}, [])

	models = getModelsWithBenefit(models, data)

	const maxBenefit = data?.find((model) => model.id === "omoda-max-benefit")?.benefit || "980 000"

	return (
		<div style={{position: "relative"}} className={styles.root}>
			<div className={styles.background}></div>
			<ChatHeader />
			{!send && (
				<main className={styles.main}>
					<div className={styles.container}>
						<ChatBanner maxBenefit={maxBenefit} />
					</div>
					<div className={styles.container}>
						<Chat setSend={setSend} models={models} />
					</div>
				</main>
			)}
			<div className={`${styles.finish}  ${send ? styles.finishActive : ""}`}>
				<ChatPopup
					title={"Ваша заявка принята!<br />Мы скоро свяжемся с Вами"}
					subtitle={"Менеджер уточнит наличие у дилера, рассчитает стоимость авто и свяжется с вами в ближайшее время"}
				/>
			</div>

			<ChatFooter />
		</div>
	)
}
