import {useState} from "react"

import styles from "./Options.module.scss"
import ChatIcon from "../../ChatIcon/ChatIcon"

export default function Options({option, setFunction}) {
	const [isActive, setIsActive] = useState(false)

	const onCheckBoxClick = () => {
		setIsActive(!isActive)
		if (isActive) {
			setFunction((prev) => prev.filter((i) => i !== option))
		} else {
			setFunction((prev) => [...prev, option])
		}
	}

	return (
		<>
			<div className={styles.wrapper}>
				<button className={styles.radio} onClick={onCheckBoxClick}>
					{isActive ? <ChatIcon.CheckOn /> : <ChatIcon.CheckOff />}
					<p className={styles.text}>{option}</p>
				</button>
			</div>
		</>
	)
}
