import {getMaxBenefit} from "./getMaxBenefit";

export const getModelsWithBenefit = (models, data) => {
	return models.map((model) => {
		let filteredModels = data
		let benefit

		if (model.folder === 'c5-awd') {
			filteredModels = data?.filter((dataModel) => ["c5-active", "c5-supreme"].includes(dataModel.id))
			benefit = getMaxBenefit(filteredModels?.map(model => model.benefit))
			return {...model, benefit}
		} else if (model.folder === 'c5') {
			filteredModels = data?.filter((dataModel) => !["c5-active", "c5-supreme"].includes(dataModel.id))
			benefit = getMaxBenefit(filteredModels?.map(model => model.benefit))
			return {...model, benefit}
		}

		if (model.folder !== 's5-gt') {
			filteredModels = data?.filter((dataModel) => !dataModel.id.match('s5-gt'))
		}

		filteredModels = filteredModels?.filter(item => item.id?.match(model?.folder))
		benefit = getMaxBenefit(filteredModels?.map(model => model.benefit))

		return {...model, benefit}
	})
}
