import {useModal} from "../hooks/useModal"
import {createContext} from "react"
import Modal from "../components/Modal"
import ChatModal from "../test-pages/ChatPage/components/ChatModal/ChatModal";

const ModalContext = createContext(undefined)

const ModalProvider = ({children}) => {
	const {modal, handleModal, modalTitle, modalMessage, model} = useModal()
	const subdomain = window?.location?.pathname?.split("/")[1]
	return (
		<ModalContext.Provider value={{modal, handleModal, modalTitle, modalMessage, model}}>
			{subdomain === "chat" ? <ChatModal /> : <Modal />}
			{children}
		</ModalContext.Provider>
	)
}

export {ModalContext, ModalProvider}
